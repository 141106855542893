/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-14",
    versionChannel: "nightly",
    buildDate: "2023-04-14T00:18:47.865Z",
    commitHash: "fef7eeed907bb03bc02e92723b21fade6b4521a7",
};
